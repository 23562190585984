<template>
  <nav class="navbar navbar-light navbar-expand-lg navigation-clean">
      <div class="container-fluid"><router-link class="navbar-brand" to="/">{{$myGlobalVariable}}</router-link><button class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navcol-2"><span class="visually-hidden">Toggle navigation</span><span class="navbar-toggler-icon"></span></button>
          <div id="navcol-2" class="collapse navbar-collapse">
              <ul class="navbar-nav ms-auto">
                  <li class="nav-item"><a class="nav-link active" href="#">Web design</a></li>
                  <li class="nav-item"><a class="nav-link" href="#">Development</a></li>
                  <li class="nav-item"><a class="nav-link" href="#">Hosting</a></li>
                  <li class="nav-item dropdown"><a class="dropdown-toggle nav-link" aria-expanded="false" data-bs-toggle="dropdown" href="#">Company </a>
                      <div class="dropdown-menu"><a class="dropdown-item" href="#">About</a><a class="dropdown-item" href="#">Support</a><a class="dropdown-item" href="#">Contact</a></div>
                  </li>
              </ul>
          </div>
      </div>
  </nav> 
</template>

<script>
export default {

}
</script>

<style>
.navigation-clean {
  background: #fff;
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #333;
  border-radius: 0;
  box-shadow: none;
  border: none;
  margin-bottom: 0;
}

@media (min-width:768px) {
  .navigation-clean {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.navigation-clean .navbar-brand {
  font-weight: bold;
  color: inherit;
}

.navigation-clean .navbar-brand:hover {
  color: #222;
}

.navigation-clean .navbar-toggler {
  border-color: #ddd;
}

.navigation-clean .navbar-toggler:hover, .navigation-clean .navbar-toggler:focus {
  background: none;
}

.navigation-clean .navbar-toggler {
  color: #888;
}

.navigation-clean.navbar-light .navbar-nav .nav-link.active, .navigation-clean.navbar-light .navbar-nav .nav-link.active:focus, .navigation-clean.navbar-light .navbar-nav .nav-link.active:hover {
  color: #8f8f8f;
  box-shadow: none;
  background: none;
  pointer-events: none;
}

.navigation-clean.navbar .navbar-nav .nav-link {
  padding-left: 18px;
  padding-right: 18px;
}

.navigation-clean.navbar-light .navbar-nav .nav-link {
  color: #465765;
}

.navigation-clean.navbar-light .navbar-nav .nav-link:focus, .navigation-clean.navbar-light .navbar-nav .nav-link:hover {
  color: #37434d !important;
  background-color: transparent;
}

.navigation-clean .navbar-nav > li > .dropdown-menu {
  margin-top: -5px;
  box-shadow: none;
  background-color: #fff;
  border-radius: 2px;
}

.navigation-clean .dropdown-menu .dropdown-item:focus, .navigation-clean .dropdown-menu .dropdown-item {
  line-height: 2;
  color: #37434d;
}

.navigation-clean .dropdown-menu .dropdown-item:focus, .navigation-clean .dropdown-menu .dropdown-item:hover {
  background: #eee;
  color: inherit;
}

</style>