<template>
<div>
  <div id="nav" >
    <!-- <router-link to="/">home</router-link> |
    <router-link to="/about">about</router-link> -->
    <!-- <ul id="menu" class="h-menu">
        <li><router-link to="/">Home</router-link></li>
        <li><router-link to="/solutions">Solutions</router-link></li>
        <li><router-link to="/support">Support</router-link></li>
        <li><router-link to="/about">About</router-link></li>
    </ul>     -->
  </div>
  <AppNavigation></AppNavigation>
  <router-view/>

    <AppFooter></AppFooter>

</div>
</template>

<script>
// @ is an alias to /src
import AppNavigation from '@/components/navigation.vue'
import AppFooter from '@/components/footer.vue'

export default {
  name: 'Home',
  components: {
    AppNavigation,
    AppFooter
  },
  created() {

  },
  mounted() {

  }
}
</script>

<style>

</style>
