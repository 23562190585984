<template>
  <AppHeader></AppHeader>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import AppHeader from '@/components/header.vue'

export default {
  name: 'Home',
  components: {
    // HelloWorld
    AppHeader
  }
}
</script>
