import { createApp } from 'vue'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import firebase from 'firebase/compat/app'
import 'firebase/compat/analytics'
import 'firebase/compat/performance'
import 'firebase/compat/remote-config'

window.App = App;

let firebaseConfig = {
    apiKey: "AIzaSyBwt2zYGOLmos4Yt5oc1z--fM7myAYf4Cc",
    authDomain: "effay-net.firebaseapp.com",
    projectId: "effay-net",
    storageBucket: "effay-net.appspot.com",
    messagingSenderId: "190510429165",
    appId: "1:190510429165:web:ce35a7edcfbe33ebb52de4",
    measurementId: "G-1BDJPXQDWG"
  };

  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
  firebase.performance();
  const remoteConfig = firebase.remoteConfig();

  const app = createApp(App)

  // const remoteConfig = getRemoteConfig();

  remoteConfig.settings = {
    minimumFetchIntervalMillis: 360000
  }


  remoteConfig.defaultConfig = ({
    'app_title': 'My WebApp',
    'standard_layout': false
  });

  // remoteConfig.fetchAndActivate();
  remoteConfig.ensureInitialized()
  .then(() => {
      // console.log('Firebase Remote Config is initialized');
      // const title = remoteConfig.getValue('app_title');
      // const standardLayout = remoteConfig.getBoolean('standard_layout');

      setApplication();
  })
  .catch((err) => {
    console.error('Firebase Remote Config failed to initialize', err);
  });
 
  function setApplication() {
    app.config.globalProperties.$myGlobalVariable = remoteConfig.getString('app_title');
    app.use(store).use(router).mount('#app')
  }




